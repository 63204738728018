import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Link } from 'react-router-dom'
import ROLES from "../../hooks/roles"
import axios from 'axios'
import {APICALL_URL, filtres1, ordres1, filtres2, ordres2} from '../../hooks/data'
import { ChevronDown, Check } from "react-bootstrap-icons"


function Menu2({actionChange, selection, getMenuAction, getFiltres, getOrdre, page, setRech, isOnline, trigger}){

    const userLocal = JSON.parse(localStorage.getItem("user"))
    const role=userLocal.roles
    const navigate = useNavigate()
    const logOut = (event) => {
        localStorage.removeItem("user")
        navigate('/login')
    }
    const [selectActive, setSelectActive] = useState(false)
    const [action, setAction] = useState("select-false")
    const [showMenu, setShowMenu] = useState(false)
    const [success, setSuccess] = useState(false)
    const [showMenuFiltres, setShowMenuFiltres] = useState(false)
    const [showMenuOrdre, setShowMenuOrdre] = useState(false)
    const [choixFiltres, setChoixFiltres] = useState("")
    const [choixOrdre, setChoixOrdre] = useState("")
    const [idChoixFiltres, setIdChoixFiltres] = useState("")
    const [drawFiltres, setDrawFiltres] = useState(filtres1)
    const [drawOrdre, setDrawOrdre] = useState(ordres1)

    useEffect(() => {
        hideMenus()
      }, [trigger])

    useEffect(() => {
        //
    }, [])

    useEffect(() => {
        if(page==="references"){
            actionFiltres(0)
            actionOrdre(0)
        }
        if(page==="references-validees"){
            actionFiltres(2)
            actionOrdre(0)
        }
        if(page==="archives"){
            actionFiltres(0)
            actionOrdre(0)
        }
        if(page==="caisses"){
            actionFiltres(5)//tous statuts
            actionOrdre(2)//croissant
        }
        if(page==="caisse"){
            actionFiltres(0)
            actionOrdre(0)
        }
    }, [page])

    useEffect(() => {
        action==="select-false" && setShowMenu(false)
    }, [selectActive])

    useEffect(() => {
        setShowMenuFiltres(false)
        setShowMenuOrdre(false)
    }, [getFiltres, getOrdre])

    const ssmenuRef=["references","references-validees","reference-new","reference-edit","reference-edit-local","synchro"]
    const ssmenuCaisses=["caisses","caisse-edit","caisse-new","caisse"]
    const ssmenuPar=["parametres","users","lieux"]
    const ssmenuCom=["compte","logs"]
    const ssmenuPublic=["archives"]
    let classAjout="picto_ssmenu refAjout"
    let classRefAll="picto_ssmenu refAll"
    let classValidees="picto_ssmenu refValidees"
    let classSynchro="picto_ssmenu synchro"
    let classSearch="picto_ssmenu search"
    let classParametres="picto_ssmenu parametres"
    let classUsers="picto_ssmenu users"
    let classLieux="picto_ssmenu lieux"
    let classLogs="picto_ssmenu logs"
    let classLogin="picto_ssmenu login"
    let classAjoutCaisse="picto_ssmenu ajoutCaisse"
    if(page==="references") classRefAll+=" active";
    if(page==="reference-new" || page==="reference-edit") classAjout+=" active";
    if(page==="references-validees") classValidees+=" active";
    if(page==="synchro") classSynchro+=" active";
    if(page==="parametres") classParametres+=" active";
    if(page==="users") classUsers+=" active";
    if(page==="lieux") classLieux+=" active";
    if(page==="logs") classLogs+=" active";

    function actionGo(action){
        if(action==="select-false") {
            action="select-true"
            setAction(action)
            actionChange(action)
            setSelectActive(true)
        } else if(action==="select-true") {
            action="select-false"
            setAction(action)
            actionChange(action)
            setSelectActive(false)
        }
    }

    function toggleMenu(){
        action==="select-true" &&
        setShowMenu(!showMenu)
    }

    function selectExport(){
        getMenuAction("exportPDF")
        setShowMenu(false)
    }
    function selectCorbeille(){
        getMenuAction("corbeille")
        setShowMenu(false)
    }

    function selectOutCorbeille(){
        getMenuAction("outCorbeille")
        setShowMenu(false)
    }

    function deleteRef(){
        getMenuAction("deleteRef")
        setShowMenu(false)
    }

    function deleteCaisse(){
        getMenuAction("deleteCaisse")
        setShowMenu(false)
    }
    function moveToCaisse(){
        getMenuAction("moveToCaisse")
        setShowMenu(false)
    }

    function toggleMenuFiltres(){
        setShowMenuFiltres(!showMenuFiltres)
    }

    function hideMenus(){
        if(showMenuFiltres) setShowMenuFiltres(false)
        if(showMenuOrdre) setShowMenuOrdre(false)
    }

    function toggleMenuOrdre(){
        setShowMenuOrdre(!showMenuOrdre)
    }

    function actionFiltres(id){
        setChoixFiltres(drawFiltres[id].texte)
        setIdChoixFiltres(id)
        getFiltres(drawFiltres[id].val)
        let newArr=drawFiltres
        for (var i = 0; i < newArr.length; i++){
            newArr[i].active=false
        }
        newArr[id].active=true
        setDrawFiltres(newArr)
        setShowMenuFiltres(false)
        setRech(false)
    }
    function actionOrdre(id){
        setChoixOrdre(drawOrdre[id].texte)
        getOrdre(drawOrdre[id].val)
        let newArr = drawOrdre
        for (var i = 0; i < newArr.length; i++){
            newArr[i].active=false
        }
        newArr[id].active=true
        setDrawOrdre(newArr)
        setShowMenuOrdre(false)
    }
    
    return(
        <div className="container menu2-cont" onClick={()=>hideMenus()}>
            {!isOnline && <div className="picto_admin offline fixed"></div>}
            <div className="logo"></div>
            { ssmenuRef.indexOf(page) > -1 &&
            <div className="menu2">
                {(page==="references" || page==="references-validees") &&
                <div className="menu_filtrage">
                    {page==="references" &&
                    <div className="menu_filtres mr-4">
                    {!showMenuFiltres &&
                    <div className="label" onClick={toggleMenuFiltres}>{choixFiltres} <ChevronDown/></div>
                    }
                    {showMenuFiltres &&
                    <ul>
                        <li onClick={()=>actionFiltres(0)}>{drawFiltres[0].active &&<Check/>} {drawFiltres[0].texte}</li>
                        <li onClick={()=>actionFiltres(1)}>{drawFiltres[1].active &&<Check/>} {drawFiltres[1].texte}</li>
                        <li onClick={()=>actionFiltres(2)}>{drawFiltres[2].active &&<Check/>} {drawFiltres[2].texte}</li>
                        <li onClick={()=>actionFiltres(3)}>{drawFiltres[3].active &&<Check/>} {drawFiltres[3].texte}</li>
                        <li onClick={()=>actionFiltres(4)}>{drawFiltres[4].active &&<Check/>} {drawFiltres[4].texte}</li>
                    </ul>
                    }
                    </div>
                    }
                    <div className="menu_ordre mr-4">
                    {!showMenuOrdre &&
                    <div className="label" onClick={toggleMenuOrdre}>{choixOrdre} <ChevronDown/></div>
                    }
                    {showMenuOrdre &&
                    <ul>
                        <li onClick={()=>actionOrdre(0)}>{drawOrdre[0].active &&<Check/>} {drawOrdre[0].texte}</li>
                        <li onClick={()=>actionOrdre(1)}>{drawOrdre[1].active &&<Check/>} {drawOrdre[1].texte}</li>
                    </ul>
                    }
                    </div>
                </div>
                }
            { (role>=ROLES.Validateur && isOnline && page!=="reference-edit") && ( <div className={classRefAll}><Link reloadDocument to='/references' key='synchro'></Link></div>) }
            {(page!=="reference-edit" && page!=="reference-edit-local" && page!=="reference-new") &&
            <>
            {role===ROLES.RegOne && ( <div className={classValidees} onClick={()=>actionFiltres(2)}><Link to='/references/tri/validees' key='references-validees'></Link></div>)}
            { ((role>ROLES.Validateur && isOnline) || role===ROLES.RegOne) && <div className={classAjout}>{(page!=="reference-edit" && page!=="reference-new") &&<Link to='/references/new' key='references-new'></Link>}</div> }
            {role===ROLES.RegOne && ( <div className={classSynchro}><Link to='/synchro' key='synchro'></Link></div>)}
            { (role>=ROLES.Validateur && isOnline && page!=="synchro") && ( <div onClick={()=>actionChange("viewSearch")} className={classSearch}></div>) }
            { page!=="synchro" &&
            <>
            <div onClick={e=>actionGo(action)} className=
            { selectActive ? "picto_ssmenu select active" : "picto_ssmenu select"}
            >
            </div>
            <div onClick={toggleMenu} className=
             { action==="select-true" ? "picto_ssmenu actions" : "picto_ssmenu actions opacity-25"}
            ></div>
            </>
            }
            {(showMenu && action==="select-true") &&
                <div className="menu_actions">
                    <ul>
                        <li onClick={selectExport}>Exporter en PDF</li>
                        {idChoixFiltres===4 &&
                        <>
                        <li onClick={selectOutCorbeille}>Déplacer dans le stock</li>
                        <li onClick={deleteRef}>Supprimer définitivement</li>
                        </>
                        }
                        <li onClick={moveToCaisse}>Déplacer dans une caisse</li>
                        {idChoixFiltres!==4 &&
                        <li onClick={selectCorbeille}>Mettre à la corbeille</li> }
                    </ul>
                </div>
            }
            </>
            }
            <select className="d-none" name="actions" onChange={(e) =>actionChange(e.currentTarget.value)}>
                <option value='test'>Test</option>
                <option value='deuze'>Deuze</option>
            </select>
            </div>
            }
            { ssmenuCaisses.indexOf(page) > -1 &&
                <div className="menu2">
                    {(page==="caisses" || page==="caisse") &&
                    <div className="menu_filtrage">
                        {(page==="caisses") &&
                        <div className="menu_filtres mr-4">
                        {!showMenuFiltres &&
                        <div className="label" onClick={toggleMenuFiltres}>{choixFiltres} <ChevronDown/></div>
                        }
                        {showMenuFiltres &&
                        <ul>
                            <li onClick={()=>actionFiltres(5)}>{drawFiltres[5].active &&<Check/>} {drawFiltres[5].texte}</li>
                            <li onClick={()=>actionFiltres(6)}>{drawFiltres[6].active &&<Check/>} {drawFiltres[6].texte}</li>
                            <li onClick={()=>actionFiltres(7)}>{drawFiltres[7].active &&<Check/>} {drawFiltres[7].texte}</li>
                        </ul>
                        }
                        </div>
                        }
                        <div className="menu_ordre mr-4">
                        {!showMenuOrdre &&
                        <div className="label" onClick={toggleMenuOrdre}>{choixOrdre} <ChevronDown/></div>
                        }
                        {showMenuOrdre &&
                        <ul>
                            <li onClick={()=>actionOrdre(2)}>{drawOrdre[2].active &&<Check/>} {drawOrdre[2].texte}</li>
                            <li onClick={()=>actionOrdre(3)}>{drawOrdre[3].active &&<Check/>} {drawOrdre[3].texte}</li>
                        </ul>
                        }
                        </div>
                    </div>
                    }
                { isOnline &&
                <>
                    <div className={classAjoutCaisse}>{(page!=="caisse-edit" && page!=="caisse-new") &&<Link to='/caisses/new' key='caisse-new'></Link>}</div>
                    {(page==="caisses" || page==="caisse") &&
                    <>
                        <div onClick={e=>actionGo(action)} className=
                        { selectActive ? "picto_ssmenu select active" : "picto_ssmenu select"}
                        >
                        </div>
                        <div onClick={toggleMenu} className=
                        { action==="select-true" ? "picto_ssmenu actions" : "picto_ssmenu actions opacity-25"}
                        ></div>
                        {(showMenu && action==="select-true") &&
                        <div className="menu_actions">
                            <span className="titre"><b>Pour la sélection :</b></span>
                            <ul>
                                <li onClick={selectExport}>Exporter en PDF</li>
                                {page==="caisse" &&
                                <li onClick={moveToCaisse}>Déplacer dans une caisse</li>
                                }
                                {page==="caisses"&&
                                <li onClick={deleteCaisse}>Supprimer la caisse</li>
                                }
                            </ul>
                        </div>
                        }
                        </>
                    }
                </>
                }
                </div>
            }
             { ssmenuPar.indexOf(page) > -1 &&
            <div className="menu2">
            { role===ROLES.RegOne && (
            <>
            <div className={classParametres}><Link to='/parametres' key='parametres'></Link></div>
            <div className={classUsers}><Link to='/users' key='users'></Link></div>
            <div className={classLieux}><Link to='/lieux' key='lieux'></Link></div>
            </>
            ) }
            </div>
            }
            { ssmenuCom.indexOf(page) > -1 &&
            <div className="menu2">
            { role===ROLES.RegOne && (
            <>
            <div className={classLogs}><Link to='/logs' key='logs'></Link></div>
            </>
            ) }
            <div className={classLogin} onClick={() =>logOut()}></div>
            </div>
            }
            { ssmenuPublic.indexOf(page) > -1 &&
            <div className="menu2">
            <>
            { (role>=ROLES.Validateur && isOnline) && ( <div onClick={()=>actionChange("viewSearch")} className={classSearch}></div>) }
            </>
            </div>
            }
            { page==="reference" &&
            <div className="menu2">
            <div></div>
            </div>
            }
        </div>
    )
}
export default Menu2